import Image from "next/image"
import { ReactEventHandler, memo, useEffect, useRef } from "react"

import { useBoolean } from "@spatialsys/react/hooks/use-boolean"
import { cn } from "@spatialsys/web/ui"

import classes from "./hero-carousel-video.module.scss"

export type HeroCarouselVideoProps = {
  onVideoEnded?: ReactEventHandler<HTMLVideoElement>
  srcMp4: string
  srcWebm: string
  title: string
  firstFrameImgSrc: string
  loadFirstFrameImage: boolean
  /**
   * If true, will play the video. Does not ever pause the video after playback has begun.
   */
  shouldPlay: boolean
  isAutoplayActive: boolean
}

/**
 * Video component that delays playing the video until `props.shouldPlay` is true.
 */
export const HeroCarouselVideo = memo(function HeroCarouselVideo(props: HeroCarouselVideoProps) {
  const { onVideoEnded, shouldPlay, isAutoplayActive, srcMp4, srcWebm, firstFrameImgSrc, loadFirstFrameImage, title } =
    props
  const videoRef = useRef<HTMLVideoElement | null>(null)
  const [isVideoLoaded, setIsVideoLoaded] = useBoolean(false)

  /** Play the video if the video component is mounted, and `shouldPlay` is true */
  useEffect(() => {
    if (!videoRef.current) {
      return
    }
    if (!shouldPlay) {
      void videoRef.current.pause()
    } else {
      void videoRef.current.play().catch(() => {})
    }
  }, [shouldPlay, isAutoplayActive /* This is needed to re-trigger even though the value is not used explicitly*/])

  useEffect(() => {
    if (!videoRef.current) return
    // This is necessary because the onCanPlayThrough event may fire before the event listener is wired up.
    if (videoRef.current.readyState >= 3) {
      setIsVideoLoaded.setTrue()
    }
  }, [setIsVideoLoaded])

  useEffect(() => {
    if (!shouldPlay && isVideoLoaded) {
      setIsVideoLoaded.setFalse()
    }
  }, [isVideoLoaded, setIsVideoLoaded, shouldPlay])

  return (
    <>
      {shouldPlay && (
        <video
          ref={videoRef}
          className="absolute left-0 top-0 z-0 h-full w-full object-cover"
          disablePictureInPicture
          disableRemotePlayback
          muted
          playsInline
          /**
           * Important: do not add `loop`, manually loop so that the `onEnded` event fires.
           */
          onEnded={onVideoEnded}
          onCanPlayThrough={setIsVideoLoaded.setTrue}
        >
          <source type="video/webm" src={srcWebm} />
          <source type="video/mp4" src={srcMp4} />
        </video>
      )}
      <Image
        alt={`${title} poster`}
        role="presentation"
        src={firstFrameImgSrc}
        className={cn(
          "absolute left-0 top-0 z-0 h-full w-full object-cover transition-opacity duration-100",
          shouldPlay && isVideoLoaded && "opacity-0"
        )}
        fill
        {...(shouldPlay ? { priority: true } : { loading: loadFirstFrameImage ? "eager" : "lazy" })}
      />
      <div className={classes.mask} />
    </>
  )
})
